<template>
	<div id="team">
		<banner :picS="bannerS" type="video"></banner>
		<div class="teamTitle">
			<titleOne id="team_1" en='team introduction' :title="lang.title.text05"></titleOne>
		</div>
		<div class="teamBox">
			<div class="widthBox">
				<teamItem :list="list"/>
			</div>
			<el-pagination v-if="total>10" :page-size="pageSize" :current-page="page" @current-change="joinChange" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import banner from '@/components/banner.vue'
	import titleOne from '@/components/titleOne.vue'
	import teamItem from '@/components/teamItem.vue'
	export default {
		name: 'team',
		components: {
			banner,
			titleOne,
			teamItem
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
			lang() {
				return this.$store.state.lang
			},
		},
		data() {
			return {
				page:1,
				pageSize:10,
				list:[],
				total:0
			}
		},
		created() {
			this.getList()
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods: {
			getList(){
				this.$api.get('home/index/teams',{
					page:this.page,
					pageSize:this.pageSize
				}).then(res=>{
					this.list = res.data.list
					this.total = res.data.total
				})
			},
			joinChange(e){
				this.page = e
				this.getJoin()
			},
		}
	}
</script>

<style lang="scss">
	#team{
		.teamTitle{
			padding:60px 0;
		}
		.el-pagination{
			margin:50px auto 0;
			text-align: center;
			button{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-weight: 500;
				background-color: rgba($color: #1D2A55, $alpha:1);
				color: #FFF;
				i{
					font-size:24px;
				}
				&:disabled {
				    color: #1D2A55;
				    background-color: rgba($color: #1D2A55, $alpha:0.1);
				    cursor: not-allowed;
				}
			}
			li{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-size:24px;
				margin:0 5px;
				font-weight: 500;
				color:rgba($color: #1D2A55, $alpha: 0.4);
				&.active{
					color:rgba($color: #1D2A55, $alpha: 1);
				}
			}
		}
		.teamBox{
			padding:60px 0;
			background:rgba($color: #1D2A55, $alpha: 0.05);
		}
	}
	
	@media screen and(max-width:768px) {
		#team{
			.el-pagination{
				button{
					line-height:32px;
					height:32px;
					i{
						font-size:20px;
					}
				}
				li{
					line-height:32px;
					height:32px;
					font-size:16px;
				}
			}
			
			.teamTitle,.teamBox{
				padding:20px 0;
			}
		}
	}
	
	
</style>
